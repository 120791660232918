export const Arrow = ({ color = "#A7B8C7" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 20.8342V1.1015C22 0.503545 21.4972 0 20.9001 0H1.16418C0.567076 0 0.0328236 0.440601 0.00139704 1.03856C-0.0300295 1.66799 0.472796 2.20301 1.10133 2.20301H16.7203C17.286 2.20301 17.5689 2.89538 17.1603 3.27304L1.60415 18.82C1.22703 19.1976 1.13275 19.8271 1.44702 20.2677C1.85557 20.8342 2.64123 20.8971 3.14405 20.425L18.7316 4.81514C19.1402 4.40601 19.8001 4.68926 19.8001 5.25575V20.8971C19.8001 21.5265 20.3344 22.0301 20.9629 21.9986C21.56 21.9671 22 21.4321 22 20.8342Z"
        fill={color}
      />
    </svg>
  );
};
