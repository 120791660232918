import { useRef, useState, useEffect } from "react";
import { Picker } from "emoji-mart";
import cn from "classnames";

import Header from "./components/Header";
import Messages from "./components/Messages";
import Sender from "./components/Sender";
import QuickButtons from "./components/QuickButtons";

import { AnyFunction } from "../../../../utils/types";

import "./style.scss";
import { useDispatch } from "react-redux";
import { setQuickButtons } from "../../../../store/actions";

interface ISenderRef {
  onSelectEmoji: (event: any) => void;
}

type Props = {
  title?: string;
  subtitle: string;
  senderPlaceHolder: string;
  showCloseButton: boolean;
  disabledInput: boolean;
  autofocus: boolean;
  className: string;
  sendAudioData: AnyFunction;
  sendMessage: AnyFunction;
  toggleChat: AnyFunction;
  profileAvatar?: string;
  profileClientAvatar?: string;
  titleAvatar?: string;
  onQuickButtonClicked?: AnyFunction;
  onTextInputChange?: (event: any) => void;
  sendButtonAlt: string;
  showTimeStamp: boolean;
  resizable?: boolean;
  emojis?: boolean;
};

function Conversation({
  title,
  subtitle,
  senderPlaceHolder,
  showCloseButton,
  disabledInput,
  autofocus,
  className,
  sendMessage,
  sendAudioData,
  toggleChat,
  profileAvatar,
  profileClientAvatar,
  titleAvatar,
  onQuickButtonClicked,
  onTextInputChange,
  sendButtonAlt,
  showTimeStamp,
  resizable,
  emojis
}: Props) {
  const dispatch = useDispatch();
  const [containerDiv, setContainerDiv] = useState<HTMLElement | null>();
  let startX, startWidth;

  useEffect(() => {
    const containerDiv = document.getElementById("rcw-conversation-container");
    setContainerDiv(containerDiv);
  }, []);

  const initResize = e => {
    if (resizable) {
      startX = e.clientX;
      if (document.defaultView && containerDiv) {
        startWidth = parseInt(
          document.defaultView.getComputedStyle(containerDiv).width
        );
        window.addEventListener("mousemove", resize, false);
        window.addEventListener("mouseup", stopResize, false);
      }
    }
  };

  const resize = e => {
    if (containerDiv) {
      containerDiv.style.width = startWidth - e.clientX + startX + "px";
    }
  };

  const stopResize = e => {
    window.removeEventListener("mousemove", resize, false);
    window.removeEventListener("mouseup", stopResize, false);
  };

  const [pickerOffset, setOffset] = useState(0);
  const senderRef = useRef<ISenderRef>(null!);
  const [pickerStatus, setPickerStatus] = useState(false);

  const onSelectEmoji = emoji => {
    senderRef.current?.onSelectEmoji(emoji);
  };

  const togglePicker = () => {
    setPickerStatus(prevPickerStatus => !prevPickerStatus);
  };

  const handlerSendMsn = event => {
    sendMessage(event);
    if (pickerStatus) setPickerStatus(false);
  };

  return (
    <div
      id="rcw-conversation-container"
      onMouseDown={initResize}
      className={cn("rcw-conversation-container", className)}
      aria-live="polite"
    >
      {resizable && <div className="rcw-conversation-resizer" />}
      <Header
        title={title}
        subtitle={subtitle}
        toggleChat={toggleChat}
        showCloseButton={showCloseButton}
        titleAvatar={titleAvatar}
      />
      <div className="rcw-conversation-container-dynamic">
        <Messages
          profileAvatar={profileAvatar}
          profileClientAvatar={profileClientAvatar}
          showTimeStamp={showTimeStamp}
        />
        <QuickButtons onQuickButtonClicked={onQuickButtonClicked} />
      </div>
      {emojis && pickerStatus && (
        <Picker
          style={{
            position: "absolute",
            bottom: pickerOffset,
            left: "0",
            width: "100%"
          }}
          onSelect={onSelectEmoji}
        />
      )}
      <Sender
        ref={senderRef}
        sendMessage={handlerSendMsn}
        sendAudioData={sendAudioData}
        placeholder={senderPlaceHolder}
        disabledInput={disabledInput}
        autofocus={autofocus}
        onTextInputChange={onTextInputChange}
        buttonAlt={sendButtonAlt}
        onPressEmoji={togglePicker}
        onChangeSize={setOffset}
      />
    </div>
  );
}

export default Conversation;
