import { useDispatch } from "react-redux";

import {
  toggleChat,
  addUserMessage,
  setQuickButtons
} from "../../store/actions";
import { isWidgetOpened } from "../../store/dispatcher";
import { AnyFunction } from "../../utils/types";

import WidgetLayout from "./layout";
import socket from "../../socket/socket";
// import socket from 'src/socket/socket';

type Props = {
  title?: string;
  titleAvatar?: string;
  subtitle: string;
  senderPlaceHolder: string;
  profileAvatar?: string;
  profileClientAvatar?: string;
  showCloseButton: boolean;
  fullScreenMode: boolean;
  autofocus: boolean;
  customLauncher?: AnyFunction;
  handleNewUserMessage: AnyFunction;
  handleQuickButtonClicked?: AnyFunction;
  handleTextInputChange?: (event: any) => void;
  chatId: string;
  handleToggle?: AnyFunction;
  launcherOpenLabel: string;
  launcherCloseLabel: string;
  launcherOpenImg: string;
  launcherCloseImg: string;
  sendButtonAlt: string;
  showTimeStamp: boolean;
  imagePreview?: boolean;
  zoomStep?: number;
  handleSubmit?: AnyFunction;
  showBadge?: boolean;
  resizable?: boolean;
  emojis?: boolean;
};

function Widget({
  title,
  titleAvatar,
  subtitle,
  senderPlaceHolder,
  profileAvatar,
  profileClientAvatar,
  showCloseButton,
  fullScreenMode,
  autofocus,
  customLauncher,
  handleNewUserMessage,
  handleQuickButtonClicked,
  handleTextInputChange,
  chatId,
  handleToggle,
  launcherOpenLabel,
  launcherCloseLabel,
  launcherCloseImg,
  launcherOpenImg,
  sendButtonAlt,
  showTimeStamp,
  imagePreview,
  zoomStep,
  handleSubmit,
  showBadge,
  resizable,
  emojis
}: Props) {
  const dispatch = useDispatch();

  const toggleConversation = () => {
    dispatch(toggleChat());
    handleToggle ? handleToggle(isWidgetOpened()) : null;
  };

  const handleMessageSubmit = userInput => {
    if (!userInput.trim()) {
      return;
    }

    handleSubmit?.(userInput);
    dispatch(addUserMessage(userInput));
    handleNewUserMessage(userInput);
    socket.sendMessage(userInput);
    console.log("user input:", userInput);
    if (userInput === "/start") {
      dispatch(
        setQuickButtons([
          {
            label: "Записаться на прием",
            value: "записаться на прием"
          },
          {
            label: "Узнать статус заявления",
            value: "узнать статус заявления"
          },
          {
            label: "Отменить запись на прием",
            value: "отменить запись на прием"
          },
          {
            label: "Узнать режим работы филиалов МФЦ",
            value: "режим работы мфц"
          },
          {
            label: "Консультация по услугам",
            value: "консультация по услугам"
          }
        ])
      );
    } else dispatch(setQuickButtons([]));

    // socket.sendMessage({
    //   "sender": {
    //     "id": "001"
    //   },
    //   "message": {
    //     "type": "text",
    //     "id": "0001",
    //     "date": 946684800,
    //     "text": userInput
    //   }
    // })
  };

  const handleSendAudioData = data => {
    socket.sendMessage(data);
  };

  const onQuickButtonClicked = (event, value) => {
    console.log("index button clicked");
    event.preventDefault();
    handleQuickButtonClicked?.(value);
  };

  return (
    <WidgetLayout
      onToggleConversation={toggleConversation}
      onSendMessage={handleMessageSubmit}
      sendAudioData={handleSendAudioData}
      onQuickButtonClicked={onQuickButtonClicked}
      title={title}
      titleAvatar={titleAvatar}
      subtitle={subtitle}
      senderPlaceHolder={senderPlaceHolder}
      profileAvatar={profileAvatar}
      profileClientAvatar={profileClientAvatar}
      showCloseButton={showCloseButton}
      fullScreenMode={fullScreenMode}
      autofocus={autofocus}
      customLauncher={customLauncher}
      onTextInputChange={handleTextInputChange}
      chatId={chatId}
      launcherOpenLabel={launcherOpenLabel}
      launcherCloseLabel={launcherCloseLabel}
      launcherCloseImg={launcherCloseImg}
      launcherOpenImg={launcherOpenImg}
      sendButtonAlt={sendButtonAlt}
      showTimeStamp={showTimeStamp}
      imagePreview={imagePreview}
      zoomStep={zoomStep}
      showBadge={showBadge}
      resizable={resizable}
      emojis={emojis}
    />
  );
}

export default Widget;
