import format from "date-fns/format";

import { MessageTypes } from "src/store/types";

import "./styles.scss";
import Linkify from "linkify-react";

type Props = {
  message: MessageTypes;
  showTimeStamp: boolean;
};

function Message({ message, showTimeStamp }: Props) {
  if (typeof message.text !== "string") return null;

  return (
    <div className={`rcw-${message.sender}`}>
      <div className="rcw-message-text">
        {/* @ts-ignore */}
        <Linkify options={{ target: "__blank" }}>{message.text}</Linkify>
      </div>
      {showTimeStamp && (
        <span className="rcw-timestamp">
          {format(message.timestamp, "HH:mm")}
        </span>
      )}
    </div>
  );
}

export default Message;
