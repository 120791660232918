export default ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width="28"
    height="28"
    viewBox="0 0 30 30"
    version="1.1"
    x="0px"
    y="0px"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <g transform="matrix(1,0,0,1,-240,-48)">
      <g transform="matrix(1.01255,0,0,0.972763,-3.3251,1.34019)">
        <path d="M250.343,72.485l12.021,-12.021c0.375,-0.375 0.586,-0.883 0.586,-1.414c-0,-0.53 -0.211,-1.039 -0.586,-1.414c-0.781,-0.781 -2.047,-0.781 -2.828,0c-2.835,2.835 -7.898,7.897 -11.314,11.314c-0.75,0.75 -1.172,1.767 -1.172,2.828c0,1.061 0.422,2.078 1.172,2.829c1.561,1.561 4.094,1.562 5.655,0.001c0,-0 11.317,-11.317 11.317,-11.317c2.342,-2.342 2.341,-6.141 -0.002,-8.483c-2.343,-2.344 -6.142,-2.344 -8.485,-0l-12.021,12.02l-1.414,-1.414l12.021,-12.021c3.124,-3.124 8.189,-3.124 11.314,0c3.123,3.124 3.124,8.189 0.001,11.313c-3.52,3.52 -8.165,8.165 -11.317,11.316c-2.342,2.343 -6.141,2.341 -8.483,-0.001c-1.126,-1.125 -1.758,-2.652 -1.758,-4.243c0,-1.591 0.632,-3.117 1.758,-4.242c3.416,-3.417 8.478,-8.479 11.313,-11.314c1.562,-1.562 4.095,-1.562 5.657,-0c0.75,0.75 1.172,1.767 1.172,2.828c-0,1.061 -0.422,2.079 -1.172,2.829l-12.021,12.02l-1.414,-1.414Z" />
      </g>
    </g>
  </svg>
);
