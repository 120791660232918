import { toggleMsgLoader } from "../store/actions";
import { SOCKET_URL } from "../constants";
import store from "../store";
import { getClientId, setClientId } from "./../localstorage/room";

let socket;

enum statuses {
  "CONNECTING",
  "STARTED"
}

class ChatSocket {
  SOCKET_URL: string;
  socket: WebSocket | null;
  status: statuses;
  room_id: string | null;
  client_id: string | null;
  awaitConnection: boolean;
  handler: ((Object) => void) | null;

  constructor(url) {
    this.status = statuses.CONNECTING;
    this.handler = null;
    this.room_id = null;
    this.client_id = null;
    this.SOCKET_URL = url;
    this.socket = null;
    this.awaitConnection = false;
  }

  connect(room_id: string, client_id: string) {
    this.awaitConnection = true;

    let client_id_from_LS = getClientId();

    if (!client_id_from_LS) {
      setClientId(Date.now().toString());
      client_id_from_LS = getClientId();
    }

    const socket = new WebSocket(`${SOCKET_URL}/${client_id_from_LS}`);
    // const socket = new WebSocket('ws://10.3.0.46/ws/room/6502dcca2ce30b7d403d5680/6502dcca2ce30b7d403d567f');

    this.socket = socket;

    const connect = () => this.connect(room_id, client_id);
    const handler = this.handler
      ? this.handler
      : event =>
          console.error(
            "Message from socket without handler function: ",
            event
          );

    socket.onopen = () => {
      this.status = statuses.STARTED;
      this.awaitConnection = false;
    };

    socket.onerror = function(error) {
      console.dir(error);
      console.error(error);
    };

    socket.onclose = () => {
      if (!this.awaitConnection) connect();
      this.awaitConnection = false;
    };

    socket.onmessage = function(event) {
      console.log("socket event: ", event);
      const data = JSON.parse(event.data);
      if (store.getState().behavior.messageLoader) {
        toggleMsgLoader();
      }
      try {
        handler(data);
      } catch (error) {
        console.error("Error in handler: ", event);
      }
    };
    return socket;
  }

  reconnectToNew() {
    this.socket?.close();
    const newClientId = Math.random()
      .toString()
      .slice(2);
    setClientId(newClientId);
    this.client_id = newClientId;
    this.socket = this.connect("", newClientId);
  }

  public subscribe(handler: (Object) => any) {
    this.handler = handler;
  }

  public async sendMessage(message: string) {
    if (this.socket) {
      // temp
      // @ts-ignore
      if (message instanceof Blob) {
        this.socket.binaryType = "arraybuffer";

        const arrayBufferMessage = await message.arrayBuffer();

        this.socket.send(arrayBufferMessage);
      } else {
        console.log("sendingMessage string", message);

        // 1730971903564
        this.socket.send(message);

        const payload = {
          // "id": "9661ab9c-48b0-11ed-a3d6-859398ff9bd9",
          // "site_id": "123456",
          // "client_id": "1233",
          // "chat_id": "2037",
          // "agents_online": true,
          // "sender": {
          //   "id": 1233,
          //   "name": "John Smith",
          //   "url": "https://test.com",
          //   "has_contacts": true
          // },
          message: {
            type: "TEXT",
            text: message,
            timestamp: new Date()
          },
          // "channel": {
          //   "id": "12345678",
          //   "type": "widget"
          // },
          event: "CLIENT_MESSAGE"
        };

        // this.socket.send(JSON.stringify(payload));
      }

      // temp

      // @ts-ignore
      // this.socket.send(JSON.stringify({
      //     "content": message,
      //     "datetime": new Date(),
      //     "type": "message"
      // }));

      // if(this.socket){
      //     console.log('sendingMessage', message);

      //     this.socket.send(JSON.stringify({
      //         "content": message,
      //         "datetime": new Date(),
      //         "type" : "message"
      //     }));
    } else {
      console.error(
        "Message from socket without socket connections: ",
        message
      );
    }
  }
}

export default new ChatSocket(`${SOCKET_URL}`);
