import { useSelector, useDispatch, shallowEqual, useStore } from "react-redux";
import cn from "classnames";

import Badge from "./components/Badge";
import { GlobalState } from "../../../../store/types";
import { setBadgeCount } from "../../../../store/actions";

import "./style.scss";
import { getRoomData } from "../../../../api/get_room_data";
import socket from "../../../../socket/socket";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

const openLauncher = require("../../../../../assets/launcher_button.png") as string;
const close = require("../../../../../assets/clear-button.svg") as string;

type Props = {
  toggle: () => void;
  chatId: string;
  openLabel: string;
  closeLabel: string;
  closeImg: string;
  openImg: string;
  showBadge?: boolean;
};

function Launcher({
  toggle,
  chatId,
  openImg,
  closeImg,
  openLabel,
  closeLabel,
  showBadge
}: Props) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const dispatch = useDispatch();
  const { showChat, badgeCount, client_id, room_id } = useSelector(
    (state: GlobalState) => ({
      showChat: state.behavior.showChat,
      badgeCount: state.messages.badgeCount,
      client_id: state.rooms.client_id,
      room_id: state.rooms.room_id
    }),
    shallowEqual
  );

  useEffect(() => {
    socket.connect("", "");

    window.addEventListener("unload", async function() {
      // @ts-ignore
      await socket.sendMessage(await new Blob(["\x04"]).arrayBuffer());
    });
  }, [client_id, room_id]);

  const toggleChat = () => {
    toggle();
    if (!showChat) dispatch(setBadgeCount(0));
  };

  const handleVKClick = () => {
    window.open(
      "https://vk.com/moidocumenty_38",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="rcw-launcher-container">
      <div className="rcw-launcher-container-main">
        <button
          id="rcw-launcher-btn"
          type="button"
          className={cn("rcw-launcher-btn", { "rcw-hide-sm": showChat })}
          onClick={toggleChat}
          aria-controls={chatId}
        >
          {!showChat && showBadge && <Badge badge={badgeCount} />}
          {showChat ? (
            <img
              src={closeImg || close}
              className="rcw-close-launcher"
              alt={openLabel}
            />
          ) : (
            <img
              src={openImg || openLauncher}
              className="rcw-open-launcher"
              alt={closeLabel}
            />
          )}
        </button>
        <Tooltip
          isOpen={isTooltipOpen && !showChat}
          setIsOpen={setIsTooltipOpen}
          place="top"
          anchorSelect="#rcw-launcher-btn"
        >
          Виртуальный консультант Василиса Лисович
        </Tooltip>
      </div>
      {!showChat && (
        <>
          <button
            onClick={handleVKClick}
            type="button"
            className={cn("rcw-launcher-btn", "rcw-launcher-btn-vk")}
          >
            VK
          </button>
        </>
      )}
    </div>
  );
}

export default Launcher;
