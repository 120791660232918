const close = require("../../../../../../../assets/clear-button.svg") as string;

import "./style.scss";

type Props = {
  subtitle: string;
  toggleChat: () => void;
  showCloseButton: boolean;
  title?: string;
  titleAvatar?: string;
};

function Header({
  title,
  subtitle,
  toggleChat,
  showCloseButton,
  titleAvatar
}: Props) {
  return (
    <div className="rcw-header">
      {showCloseButton && (
        <button className="rcw-close-button" onClick={toggleChat}>
          <img src={close} className="rcw-close" alt="close" />
        </button>
      )}
      {Boolean(title || titleAvatar) && (
        <h4 className="rcw-title">
          {titleAvatar && (
            <img src={titleAvatar} className="avatar" alt="profile" />
          )}
          {title}
        </h4>
      )}
      <span className="rcw-subtitle">{subtitle}</span>
    </div>
  );
}

export default Header;
