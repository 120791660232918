import { MouseEvent, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GlobalState, QuickButtonTypes } from "src/store/types";
import { AnyFunction } from "src/utils/types";

import "./style.scss";
import QuickButton from "./components/QuickButton";
import {
  addUserMessage,
  setQuickButtons
} from "../../../../../../store/dispatcher";
import socket from "../../../../../../socket/socket";

type Props = {
  onQuickButtonClicked?: AnyFunction;
};

function QuickButtons({ onQuickButtonClicked }: Props) {
  const buttons = useSelector(
    (state: GlobalState) => state.quickButtons.quickButtons
  );

  const handleButtonClick = (
    event: MouseEvent,
    value: QuickButtonTypes["value"]
  ) => {
    const strValue = String(value);

    addUserMessage(strValue);
    socket.sendMessage(strValue);
    setQuickButtons([]);

    onQuickButtonClicked?.(event, value);
  };

  const getComponentToRender = (button: QuickButtonTypes) => {
    const ComponentToRender = button.component;
    return (
      //@ts-ignore
      <ComponentToRender
        onQuickButtonClicked={handleButtonClick}
        button={button}
      />
    );
  };

  if (!buttons.length) return null;

  return (
    <div className="quick-buttons-container">
      {buttons.map((button, index) => (
        <Fragment key={index}>{getComponentToRender(button)}</Fragment>
      ))}
    </div>
  );
}

export default QuickButtons;
